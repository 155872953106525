<template>

  <div>

    <branch-list-add-new
      :is-add-new-branch-sidebar-active.sync="isAddNewBranchSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <branches-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

    <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="1"
            md="12">
          <!-- <download-excel class="btn btn-success float-right" :data="fetchInvoicesConfirmedUnsliced" :fields="invoicesAllFields"
  worksheet="Invoices"
  name="invoice_confirmed_internal.csv">
          Export Excel
          </download-excel> -->
            <vue-json-to-csv
              class = "btn btn-success float-right"
              :csv-title="'branch_list'"
              :json-data = "fetchBranchList"
              :labels = "labels"
              >
              Download CSV
            </vue-json-to-csv>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                v-if="userRole=='super-admin'"
                @click="isAddNewBranchSidebarActive = true"
              >
                <span class="text-nowrap">Add Branch</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refBranchListTable"
        class="position-relative"
        :items="fetchBranches"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Branch -->
        <template #cell(branch)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.FullName)"
                :variant="`light-${resolveBranchRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-branches-view', params: { id: data.item.BranchID } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-branches-view', params: { id: data.item.BranchID } }"
              class="font-weight-bold text-nowrap"
            >
              {{ data.item.FullName.charAt(0).toUpperCase() + data.item.FullName.slice(1) }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(phone)="data">
          <div class="text-nowrap">
            <feather-TrashIcon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.PhoneNumber }}</span>
          </div>
        </template>

        <!-- Column: Gender -->
        <template #cell(gender)="data">
          <div class="text-nowrap">
            <feather-TrashIcon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.Gender }}</span>
          </div>
        </template>

        <!-- Column: UnitKerja -->
        <template #cell(unitKerja)="data">
          <div class="text-nowrap">
            <feather-TrashIcon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.KodeUnitKerja }}</span>
          </div>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <feather-TrashIcon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.Email }}</span>
          </div>
        </template>

        <!-- Column: Ctyh -->
        <template #cell(city)="data">
          <div class="text-nowrap">
            <feather-TrashIcon
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{ data.item.Kota }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveBranchRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveBranchRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.BranchRoleName }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveBranchStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editBranch( JSON.stringify( data.item ) )">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteBranch( data.item.BranchID )">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBranches"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'
import { getToken } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueJsonToCsv from 'vue-json-to-csv'
// import BranchesListFilters from './BranchesListFilters.vue'
import useBranchesList from './useBranchesList'
import branchStoreModule from '../branchStoreModule'
import BranchListAddNew from './BranchListAddNew.vue'

const branchToken = getToken()
const userRole = ''
const fetchBranchList = []
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${branchToken}`,
}

export default {
  components: {
    // BranchesListFilters,
    BranchListAddNew,
    VueJsonToCsv,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  async mounted() {
    this.userRole = this.$cookies.get('UserRole')
    await this.fetchBranchesCsv()
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refBranchListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-branch'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, branchStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewBranchSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchBranches,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalBranches,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,

      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useBranchesList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {

      // Sidebar
      isAddNewBranchSidebarActive,
      userRole,
      fetchBranches,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalBranches,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      labels: {
                Kota: { title: 'Kota' },
                KodeUnitKerja: { title: 'Kode Unit Kerja' },
                Deskripsi: { title: 'Deskripsi' },
                Alamat1: { title: 'Alamat 1' },
                Alamat2: { title: 'Alamat 2' },
                Telp1: { title: 'Telp1' },
                Telp2: { title: 'Telp2' },
                KodeArea: { title: 'KodeArea' },
                EmailPembayaran: { title: 'Email Pembayaran' },
                EmailDeposit: { title: 'Email Deposit' },
        },
    }
  },
  data() {
    return {
      fetchBranchList,
    }
  },
  methods: {
        async fetchBranchesCsv() {
          axios
            .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
            .then(response => {
             const allReceived = []
              response.data.Payload.map(o => {
          const temp = {
            Kota: o.Kota,
            KodeUnitKerja: o.KodeUnitKerja,
            Deskripsi: o.Deskripsi,
            Alamat1: o.Alamat1,
            Alamat2: o.Alamat2,
            Telp1: o.Telp1,
            Telp2: o.Telp2,
            KodeArea: o.KodeArea,
            EmailPembayaran: o.EmailPembayaran,
            EmailDeposit: o.EmailDeposit,
            }
            allReceived.push(temp)
              })
              this.fetchBranchList = allReceived
            console.log(fetchBranchList)
            })
            .catch(error => {
              console.log(error)
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        },
      deleteBranch(id) {
        const body = {
          BranchID: id.toString(),
        }
          axios
              .post(process.env.VUE_APP_API_DELETE_BRANCH, body, { headers })
              .then(response => {
                console.log(response)
                if (response.data.Status === 1) {
                  this.$refs.refBranchListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editBranch(data) {
         console.log('ehem', data)
          localStorage.setItem('branchEdit', data)
          this.$router.push({ name: 'apps-branches-edit' })
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
